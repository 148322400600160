import { IncomeDisabilityAssessmentDetailsView } from '../../assessment/decisions';
import { DisabilitySynopsis } from '../../assessment/synopsis';
import { ClaimAuditTrailView } from '../../claim-audit-trail';
import { ClaimType } from '../../claim/claim-types';
import { PaymentsView } from '../../payment';
import { DashboardFlowView } from '../../assesor-dashboard/views/dashboard/assessor-dashboard';
import { WorkflowScreen } from '../../workflow-navigation/reducers/workflow-screen';
import { WorkflowStep } from '../../workflow-navigation/reducers/workflow-step';
import { WorkflowUtilityScreen } from '../../workflow-navigation/reducers/workflow-utility-screen';
import { WorkflowAssessorScreen } from '../../workflow-navigation/reducers/workflow-assessor';
import { ClaimWorkflow } from './claim-workflow';
import { ScreenGuard } from './screen-index';
import { AuthGuard } from '../../auth/authGuard';
import { LivingLifestyleSynopsis } from '../../assessment/synopsis/living-lifestyle/living-lifestyle-synopsis';

const assessorDashboardGuard = AuthGuard(['AssessorDashboard']);
const underwritingGuard = AuthGuard(['Underwriting', 'Agent']);

export const disabilityWorkflow = new ClaimWorkflow(
    'Disability',
    [
        new WorkflowStep('Initiate Claim', []),
        new WorkflowStep('Assessment', [
            new WorkflowScreen('Synopsis', DisabilitySynopsis, undefined, undefined, ScreenGuard),
            new WorkflowScreen(
                'Decisions',
                IncomeDisabilityAssessmentDetailsView,
                undefined,
                undefined,
                ScreenGuard
            ),
        ]),
        new WorkflowStep('Underwriting', [
            new WorkflowScreen(
                'Synopsis ',
                LivingLifestyleSynopsis,
                undefined,
                undefined,
                underwritingGuard
            ),
            new WorkflowScreen(' ', undefined, undefined, undefined, underwritingGuard),
        ]),
        new WorkflowStep('Payments', [
            new WorkflowScreen('Payments', PaymentsView, undefined, undefined, () => false),
        ]),
        new WorkflowAssessorScreen(
            'Assessor List',
            'Assessor limits dashboard',
            DashboardFlowView,
            undefined,
            'Assessor limits dashboard',
            assessorDashboardGuard
        ),
        new WorkflowUtilityScreen(
            'Claim Audit Trail',
            'list',
            ClaimAuditTrailView,
            undefined,
            undefined,
            ScreenGuard
        ),
    ],
    claim => claim.claimType === ClaimType.Disability,
    undefined,
    ScreenGuard
);
