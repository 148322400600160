import React from 'react'; // eslint-disable-line
import { ContactPersonView } from '../common/contact-person';
import { LifeAssuredView } from '../common/life-assured';
import { PolicySummaryView } from '../common/policy-summary';
import { UnderwritingView } from '../common/underwriting';
import { AuthGuard } from '../../../auth/authGuard';
import { UnderwritingDecision } from '../common/synopsis/underwriting-decision';
import { RetrospectiveUnderwritingDecisionView } from '../common/retrospective-underwriting-decision';

export const LivingLifestyleSynopsis = () => {
    const hasUnderwritterAccess = AuthGuard(['Agent']);

    return (
        <UnderwritingDecision>
            <ContactPersonView isUnderWriting={true} />
            <LifeAssuredView isUnderWriting={true} />
            <PolicySummaryView isUnderWriting={true} />
            <UnderwritingView isUnderWriting={hasUnderwritterAccess()} />
            <RetrospectiveUnderwritingDecisionView />
        </UnderwritingDecision>
    );
};
