import React from 'react'; // eslint-disable-line

import { Grid, withStyles } from '@material-ui/core';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { UnderwritingReferralsView } from './components/underwriting-referrals';
import { getUnderwriting, Underwriting } from './underwriting-reducer';
import { PolicyUnderwritingView } from './policy-underwriting-view';
import { UwInformationRecordView } from '../information-record/views/underwriting-information-record';

interface OwnProps {
    isUnderWriting?: boolean;
}

interface StateProps {
    underwriting: Underwriting;
}

interface DispatchProps {}

type Props = OwnProps & StateProps & DispatchProps & StyledComponent;
const underwritingView: React.FunctionComponent<Props> = ({
    underwriting,
    isUnderWriting,
}: Props) => {
    return (
        <ExpansionPanel name="Underwriting Information Record">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UwInformationRecordView />
                </Grid>
                <Grid item xs={12}>
                    {underwriting.details.map((policyUnderwritingDetails, policyIndex) => (
                        <PolicyUnderwritingView
                            key={policyUnderwritingDetails.policyDetails.policyNumber}
                            policyIndex={policyIndex}
                            policyUnderwritingDetails={policyUnderwritingDetails}
                            isUnderWriting={isUnderWriting}
                        />
                    ))}
                </Grid>
                <Grid item xs={12}>
                    <UnderwritingReferralsView
                        referrals={underwriting.referrals}
                        isUnderWriting={isUnderWriting}
                    />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    underwriting: getUnderwriting(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {};

export const UnderwritingView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(underwritingView));
