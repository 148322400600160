export * from './claim-condition-dread';
export const ClaimConditions = [
    { value: 'Accidental HIV', label: 'Accidental HIV' },
    { value: 'AIDS', label: 'AIDS' },
    {
        value: `Alzheimer's disease and progressive dementia`,
        label: `Alzheimer's disease and progressive dementia`,
    },
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 1 or more Basic ADLs, and permanent inability to perform 2 or more Instrumental ADLs',
    },
    {
        value:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
        label:
            'Activities of Daily Living catch-all - Permanent inability to perform 3 or more Basic ADLs, and permanent inability to perform 4 or more Instrumental ADLs',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 2 weeks.',
    },
    {
        value:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
        label:
            'Anaemia - Persistent haemoglobin levels of 8g/dl or less; and transfusions of at least 2 units of blood required every 6 weeks',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence not amenable to colostomy',
    },
    {
        value:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
        label:
            'Anorectal disease - Complete and permanent faecal incontinence treated with permanent colostomy',
    },
    {
        value: 'Anorectal disease - Partial but permanent faecal incontinence',
        label: 'Anorectal disease - Partial but permanent faecal incontinence',
    },
    {
        value: 'Aphasia/dysphasia - Receptive aphasia or dysphasia',
        label: 'Aphasia/dysphasia - Receptive aphasia or dysphasia',
    },
    {
        value:
            'Arrhythmias - Symptoms are present continually and documented on Holter ECG and NYHA IV',
        label:
            'Arrhythmias - Symptoms are present continually and documented on Holter ECG and NYHA IV',
    },
    {
        value:
            'Arrhythmias - Symptoms are present frequently and documented on Holter ECG and NYHA III',
        label:
            'Arrhythmias - Symptoms are present frequently and documented on Holter ECG and NYHA III',
    },
    {
        value: 'Biliary tract - Irreparable biliary tract obstruction',
        label: 'Biliary tract - Irreparable biliary tract obstruction',
    },
    {
        value: 'Bladder - Surgical removal of the bladder',
        label: 'Bladder - Surgical removal of the bladder',
    },
    {
        value:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
        label:
            'Bladder - Total urinary incontinence, loss of reflex or voluntary urine control despite medical and surgical treatment',
    },
    {
        value: 'BCC/SCC',
        label: 'BCC/SCC',
    },
    {
        value:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
        label:
            'Cancer - Cancer which has resulted in documented permanent end-organ dysfunction or damage - assessed based on the affected end-organ system.',
    },
    {
        value: 'Cancer and leukaemia - Blood/bone marrow cancer',
        label: 'Cancer and leukaemia - Blood/bone marrow cancer',
    },
    { value: 'Cancer and leukaemia - Brain cancer', label: 'Cancer and leukaemia - Brain cancer' },
    {
        value: 'Cancer and leukaemia - Breast cancer',
        label: 'Cancer and leukaemia - Breast cancer',
    },
    {
        value: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
        label: 'Cancer and leukaemia - Diagnosis of acute myoblastic leukaemia with poor prognosis',
    },
    {
        value: 'Cancer and leukaemia - Failure of leukaemia treatment',
        label: 'Cancer and leukaemia - Failure of leukaemia treatment',
    },
    { value: 'Cancer and leukaemia - Lung cancer', label: 'Cancer and leukaemia - Lung cancer' },
    { value: 'Cancer and leukaemia - Lymph cancer', label: 'Cancer and leukaemia - Lymph cancer' },
    {
        value: 'Cancer and leukaemia - Metastatic cancer',
        label: 'Cancer and leukaemia - Metastatic cancer',
    },
    {
        value: 'Cancer and leukaemia - Prostate cancer',
        label: 'Cancer and leukaemia - Prostate cancer',
    },
    {
        value: 'Cancer and leukaemia - Relapse of leukaemia',
        label: 'Cancer and leukaemia - Relapse of leukaemia',
    },
    {
        value: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
        label: 'Cancer and leukaemia - Requiring a bone marrow transplant for leukaemia',
    },
    { value: 'Cancer and leukaemia - Skin cancer', label: 'Cancer and leukaemia - Skin cancer' },
    { value: 'Cardiomyopathy - EF 35-40%', label: 'Cardiomyopathy - EF 35-40%' },
    { value: 'Cardiomyopathy - EF less than 35%', label: 'Cardiomyopathy - EF less than 35%' },
    { value: 'Cardiomyopathy - EF less than 45%', label: 'Cardiomyopathy - EF less than 45%' },
    { value: 'Cardiomyopathy - METS 2-5', label: 'Cardiomyopathy - METS 2-5' },
    { value: 'Cardiomyopathy - METS 5 or less', label: 'Cardiomyopathy - METS 5 or less' },
    { value: 'Cardiomyopathy - METS less than 2', label: 'Cardiomyopathy - METS less than 2' },
    { value: 'Cardiomyopathy - NYHA III', label: 'Cardiomyopathy - NYHA III' },
    {
        value: 'Cardiomyopathy - NYHA III or greater',
        label: 'Cardiomyopathy - NYHA III or greater',
    },
    { value: 'Cardiomyopathy - NYHA IV', label: 'Cardiomyopathy - NYHA IV' },
    {
        value: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
        label: 'Cardiomyopathy - Recovered from surgery for hypertrophic cardiomyopathy',
    },
    {
        value:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
        label:
            'Cardiovascular system - Receiving optimal treatment, but still symptomatic NYHA class 4, with METS < 3 or EF < 40%',
    },
    {
        value: 'Carcinoid',
        label: 'Carcinoid',
    },
    {
        value:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
        label:
            'Clotting disorders - Bleeds or clots which have resulted in documented permanent end-organ dysfunction or damage in spite of surgical or medical treatment',
    },
    { value: 'Coronary artery disease - EF 35-40%', label: 'Coronary artery disease - EF 35-40%' },
    {
        value: 'Coronary artery disease - EF less than 35%',
        label: 'Coronary artery disease - EF less than 35%',
    },
    {
        value: 'Coronary artery disease - EF less than 45%',
        label: 'Coronary artery disease - EF less than 45%',
    },
    { value: 'Coronary artery disease - METS 2-5', label: 'Coronary artery disease - METS 2-5' },
    {
        value: 'Coronary artery disease - METS 5 or less',
        label: 'Coronary artery disease - METS 5 or less',
    },
    {
        value: 'Coronary artery disease - METS less than 2',
        label: 'Coronary artery disease - METS less than 2',
    },
    { value: 'Coronary artery disease - NYHA III', label: 'Coronary artery disease - NYHA III' },
    {
        value: 'Coronary artery disease - NYHA III or greater',
        label: 'Coronary artery disease - NYHA III or greater',
    },
    { value: 'Coronary artery disease - NYHA IV', label: 'Coronary artery disease - NYHA IV' },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Inability to swallow, requiring regular suctioning to prevent aspiration or choking',
    },
    {
        value:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
        label:
            'Cranial nerve IX, X, XII (glossopharyngeal, vagal, hypoglossal nerves) - Moderate to severe dysarthria or dysphasia, nasal regurgitation and aspiration',
    },
    {
        value:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
        label:
            'Cranial nerve V (trigeminal nerve) - Severe uncontrolled unilateral or bilateral facial neuralgic pain affecting either speech or eating',
    },
    {
        value:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
        label:
            'Cranial nerve VII (facial nerve) - Unilateral or bilateral facial nerve paralysis with the inability to control eyelid closure',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms  leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.     ',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and presence of symptoms  leading to the inability to climb stairs or walk unaided by another person, with abnormal findings on electronystagmography or videonystagmography.     ',
    },
    {
        value:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of listed symptoms',
        label:
            'Cranial nerve VIII (vestibulocochlear nerve) - Inability to communicate in a group with documented tinnitus induced hearing loss; and the presence of listed symptoms',
    },
    {
        value: 'Diplegia / paraplegia - Permanent diplegia / paraplegia',
        label: 'Diplegia / paraplegia - Permanent diplegia / paraplegia',
    },
    {
        value: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA III',
        label: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA III',
    },
    {
        value: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA IV',
        label: 'Diseases of the aorta - Persistent symptoms despite medication and NYHA IV',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with NYHA III',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 40mm Hg with right heart failure',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with NYHA IV',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure greater than 70mm Hg with right heart failure',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with NYHA III',
    },
    {
        value:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
        label:
            'Diseases of the pulmonary arteries - Systolic Pulmonary Artery Pressure of 40-70mm Hg with right heart failure',
    },
    {
        value: 'Episodic loss of consciousness - Seizures',
        label: 'Episodic loss of consciousness - Seizures',
    },
    {
        value: 'Episodic loss of consciousness - Status epilepticus',
        label: 'Episodic loss of consciousness - Status epilepticus',
    },
    { value: 'Face - Disfigurement', label: 'Face - Disfigurement' },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Difficulty with grasping, pincer grip, fine or gross motor coordination or grip strength',
    },
    {
        value:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
        label:
            'Gait disorders/motor coordination - Difficulty with standing, maintaining a standing position and needing assistance with walking',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions and documented previous falls and inability to stand, walk, stoop, squat, kneel, climb stairs',
    },
    {
        value:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
        label:
            'Gait disorders/motor coordination - Needing assistive devices or mechanical support for daily functions; and inability to grasp, pincer grip and inability with fine or gross motor coordination or grip strength',
    },
    {
        value: 'Hearing - Total and irreversible average hearing loss',
        label: 'Hearing - Total and irreversible average hearing loss',
    },
    { value: 'Heart transplant - Heart transplant', label: 'Heart transplant - Heart transplant' },
    { value: 'Hemiplegia - Permanent hemiplegia', label: 'Hemiplegia - Permanent hemiplegia' },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications (strangulations, obstructions, ischaemia and gangrene)',
    },
    {
        value:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
        label:
            'Hernia - Persistent, irreducible or irreparable protrusion of hernia; and development of ongoing complications, as specified in the policy document, and; weight loss resulting in a BMI of less than 14.5.',
    },
    {
        value: 'Hypertensive cardiovascular disease - Documented Stage 3 hypertension',
        label: 'Hypertensive cardiovascular disease - Documented Stage 3 hypertension',
    },
    {
        value: 'Hypertensive cardiovascular disease - Renal system impairment',
        label: 'Hypertensive cardiovascular disease - Renal system impairment',
    },
    { value: 'Impairment of consciousness - Coma', label: 'Impairment of consciousness - Coma' },
    {
        value: 'Liver disease - Abnormal liver function tests',
        label: 'Liver disease - Abnormal liver function tests',
    },
    {
        value:
            'Liver disease - Persistent jaundice and bleeding varies, hepatoxicity and severe symptoms and signs uncontrolled by medical or surgical treatment',
        label:
            'Liver disease - Persistent jaundice and bleeding varies, hepatoxicity and severe symptoms and signs uncontrolled by medical or surgical treatment',
    },
    {
        value:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Lower gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment; and weight loss resulting in a BMI of less than 16 due to disease.',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
    },
    {
        value:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Lower gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Bilateral involvement requiring use of assistive devices for hand function',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: cauda equine syndrome',
    },
    {
        value:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
        label:
            'Lumbar, thoracic and cervical spine - Radiculopathy and signs of chronic neurological impairment with special investigations changes; and: unilateral involvement requiring use of assistive devices for hand function',
    },
    {
        value: 'Malignant melanoma',
        label: 'Malignant melanoma',
    },
    {
        value: 'Multiple myeloma',
        label: 'Multiple myeloma',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed',
    },
    {
        value:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
        label:
            'Neurological impairment of respiration - Neurological impairment of respiration with permanent confinement to bed but not requiring ventilator assistance',
    },
    {
        value:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
        label:
            'Neurological impairment of respiration - No capacity for spontaneous respiration and requiring ventilation',
    },
    {
        value:
            'Organic brain disorders and dementia - Organic brain disorders and clinical dementia',
        label:
            'Organic brain disorders and dementia - Organic brain disorders and clinical dementia',
    },
    { value: 'Paralysis - Hemiplegia', label: 'Paralysis - Hemiplegia' },
    { value: 'Paralysis - Paraplegia', label: 'Paralysis - Paraplegia' },
    { value: 'Paralysis - Quadriplegia', label: 'Paralysis - Quadriplegia' },
    { value: 'Pericardial disease - EF 35-40%', label: 'Pericardial disease - EF 35-40%' },
    {
        value: 'Pericardial disease - EF less than 35%',
        label: 'Pericardial disease - EF less than 35%',
    },
    {
        value: 'Pericardial disease - EF less than 45%',
        label: 'Pericardial disease - EF less than 45%',
    },
    { value: 'Pericardial disease - METS 2-5', label: 'Pericardial disease - METS 2-5' },
    {
        value: 'Pericardial disease - METS 5 or less',
        label: 'Pericardial disease - METS 5 or less',
    },
    {
        value: 'Pericardial disease - METS less than 2',
        label: 'Pericardial disease - METS less than 2',
    },
    { value: 'Pericardial disease - NYHA III', label: 'Pericardial disease - NYHA III' },
    {
        value: 'Pericardial disease - NYHA III or greater',
        label: 'Pericardial disease - NYHA III or greater',
    },
    { value: 'Pericardial disease - NYHA IV', label: 'Pericardial disease - NYHA IV' },
    {
        value:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
        label:
            'Peripheral arterial disease - Abnormal diminished pulse on Doppler Readings and Cold leg; and Pain on exercise',
    },
    {
        value: 'Peripheral arterial disease - Gangrene',
        label: 'Peripheral arterial disease - Gangrene',
    },
    {
        value:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
        label:
            'Peripheral arterial disease - Persistent ischaemia with no detectable pulse on Doppler readings',
    },
    {
        value: 'Peripheral arterial disease - Severe vascular ulceration',
        label: 'Peripheral arterial disease - Severe vascular ulceration',
    },
    {
        value: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
        label: 'Peripheral venous disease - Severe, deep and widespread vascular ulceration',
    },
    {
        value: 'Quadriplegia - Permanent quadriplegia',
        label: 'Quadriplegia - Permanent quadriplegia',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
        label:
            'Schizophrenia/other pyschotic disorders - Institutionalised at least 3 times in the last 12 months with each admission lasting for longer than 6 weeks; and GAF less than 40.',
    },
    {
        value:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
        label:
            'Schizophrenia/other pyschotic disorders - Requiring permanent institutionalisation or permanent caregiver; and GAF less than 30',
    },
    {
        value: 'Sleep disorders - Essential narcolepsy',
        label: 'Sleep disorders - Essential narcolepsy',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia',
        label:
            'Stroke (cerebrovascular incident) - Either four of the following: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, Moderate cognitive problems, or Homonymous Hemianopia',
    },
    {
        value:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
        label:
            'Stroke (cerebrovascular incident) - The inability to perform any one of the following categories: Moderate communication difficulties, Moderate difficulties with posture and motion, Moderate difficulties with fine and gross motor functions, or Moderate cognitive problems',
    },
    {
        value:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
        label:
            'The endocrine system - Symptoms and signs of abnormality of the endocrine system leading to targer end-organ damage despite appropriate surgical and medical treatment including replacement or blockades',
    },
    {
        value:
            'The limbs - At least 2 of the following: Hand at MP joint, Arm below the elbow, Arm above the elbow, Leg through the hip, Leg above the knee, Leg below the knee',
        label:
            'The limbs - At least 2 of the following: Hand at MP joint, Arm below the elbow, Arm above the elbow, Leg through the hip, Leg above the knee, Leg below the knee',
    },
    { value: 'The limbs - Hemipelvectomy', label: 'The limbs - Hemipelvectomy' },
    {
        value: 'The limbs - Loss of or loss of use of arm below the elbow',
        label: 'The limbs - Loss of or loss of use of arm below the elbow',
    },
    {
        value: 'The limbs - Loss of or loss of use of arm below the shoulder',
        label: 'The limbs - Loss of or loss of use of arm below the shoulder',
    },
    {
        value: 'The limbs - Loss of or loss of use of foot',
        label: 'The limbs - Loss of or loss of use of foot',
    },
    {
        value: 'The limbs - Loss of or loss of use of hand at MP joint',
        label: 'The limbs - Loss of or loss of use of hand at MP joint',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg above the kness',
        label: 'The limbs - Loss of or loss of use of leg above the kness',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg below the knee',
        label: 'The limbs - Loss of or loss of use of leg below the knee',
    },
    {
        value: 'The limbs - Loss of or loss of use of leg through the hip',
        label: 'The limbs - Loss of or loss of use of leg through the hip',
    },
    {
        value: 'The limbs - Loss of or loss of use of thumb through MP joint',
        label: 'The limbs - Loss of or loss of use of thumb through MP joint',
    },
    {
        value: 'The respiratory system - DCO less than 40% of predicted',
        label: 'The respiratory system - DCO less than 40% of predicted',
    },
    {
        value: 'The respiratory system - DCO less than 60% of predicted',
        label: 'The respiratory system - DCO less than 60% of predicted',
    },
    {
        value: 'The respiratory system - DCO of 40 - 59% of predicted',
        label: 'The respiratory system - DCO of 40 - 59% of predicted',
    },
    {
        value: 'The respiratory system - FEV1 less than 40% of predicted',
        label: 'The respiratory system - FEV1 less than 40% of predicted',
    },
    {
        value: 'The respiratory system - FEV1 less than 60% of predicted',
        label: 'The respiratory system - FEV1 less than 60% of predicted',
    },
    {
        value: 'The respiratory system - FEV1 of 40 - 59% of predicted',
        label: 'The respiratory system - FEV1 of 40 - 59% of predicted',
    },
    {
        value: 'The respiratory system - FVC less than 40% of predicted',
        label: 'The respiratory system - FVC less than 40% of predicted',
    },
    {
        value: 'The respiratory system - FVC less than 60% of predicted',
        label: 'The respiratory system - FVC less than 60% of predicted',
    },
    {
        value: 'The respiratory system - FVC of 40 - 49% of predicted',
        label: 'The respiratory system - FVC of 40 - 49% of predicted',
    },
    {
        value: 'The respiratory system - FVC of 50 - 59% of predicted',
        label: 'The respiratory system - FVC of 50 - 59% of predicted',
    },
    {
        value:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
        label:
            'The skin - Extensive permanent involvement of an upper and lower limb including the sole of one foot and the palm of one hand',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
        label:
            'The skin - Extensive permanent involvement of the bilateral lower limbs including the soles of both feet',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands',
    },
    {
        value:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
        label:
            'The skin - Extensive permanent involvement of the bilateral upper limbs including the palms of both hands; and restriction of joint mobility',
    },
    {
        value:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 20% of the total body surface area',
    },
    {
        value:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
        label:
            'The skin - Third degree burns involving more than 30% of the total body surface area',
    },
    {
        value: 'The visual system - Diabetic retinopathy',
        label: 'The visual system - Diabetic retinopathy',
    },
    { value: 'The visual system - Hemianopia', label: 'The visual system - Hemianopia' },
    {
        value: 'The visual system - Irreversible visual acuity impairment',
        label: 'The visual system - Irreversible visual acuity impairment',
    },
    { value: 'Upper air passages - Dyspnoea', label: 'Upper air passages - Dyspnoea' },
    {
        value:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
        label:
            'Upper gastrointestinal tract - Moderate to severe exacerbations with severe pain; and symptoms and signs not completely controlled by medical or surgical treatment and weight loss resulting in a BMI of less than 16 due to disease.',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 14.5 due to disease.',
    },
    {
        value:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
        label:
            'Upper gastrointestinal tract - Unremitting severe symptoms and signs uncontrolled by medical or surgical treatment; and weight loss resulting in a BMI of less than 15.5 due to disease.',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR 24-40ml/min despite treatment',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 24ml/min despite treatment',
    },
    {
        value:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
        label:
            'Upper urinary tract - Irreversible kidney failure with GFR less than 40ml/min despite treatment',
    },
    {
        value: 'Upper urinary tract - Requires ongoing dialysis',
        label: 'Upper urinary tract - Requires ongoing dialysis',
    },
    {
        value:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
        label:
            'Urethra - Urethral dysfunction and loss of voluntary urine control; and requiring an indwelling catheter.',
    },
    { value: 'Valvular heart disease - EF 35-40%', label: 'Valvular heart disease - EF 35-40%' },
    {
        value: 'Valvular heart disease - EF less than 35%',
        label: 'Valvular heart disease - EF less than 35%',
    },
    {
        value: 'Valvular heart disease - EF less than 45%',
        label: 'Valvular heart disease - EF less than 45%',
    },
    { value: 'Valvular heart disease - METS 2-5', label: 'Valvular heart disease - METS 2-5' },
    {
        value: 'Valvular heart disease - METS 5 or less',
        label: 'Valvular heart disease - METS 5 or less',
    },
    {
        value: 'Valvular heart disease - METS less than 2',
        label: 'Valvular heart disease - METS less than 2',
    },
    { value: 'Valvular heart disease - NYHA III', label: 'Valvular heart disease - NYHA III' },
    {
        value: 'Valvular heart disease - NYHA III or greater',
        label: 'Valvular heart disease - NYHA III or greater',
    },
    { value: 'Valvular heart disease - NYHA IV', label: 'Valvular heart disease - NYHA IV' },
    {
        value: 'Vestibular disorders - Vestibular dyequilibrium',
        label: 'Vestibular disorders - Vestibular dyequilibrium',
    },
    {
        value: 'Voice/speech impairment - Failure of all three functions',
        label: 'Voice/speech impairment - Failure of all three functions',
    },
    {
        value: 'Voice/speech impairment - Failure of all three moderate functions',
        label: 'Voice/speech impairment - Failure of all three moderate functions',
    },
    {
        value: 'Voice/speech impairment - Failure of all three severe functions',
        label: 'Voice/speech impairment - Failure of all three severe functions',
    },
    {
        value:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
        label:
            'Voice/speech impairment - Total and permanent loss of the ability to speak as confirmed by an appropriate specialist',
    },
    {
        value: 'White blood cells - Leukaemia, lymphoma and white cell abnormalities',
        label: 'White blood cells - Leukaemia, lymphoma and white cell abnormalities',
    },
    { value: 'Other', label: 'Other' },
];
