import { Button, Checkbox, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../../form-modal/actions';
import { FormModalView } from '../../../../../form-modal/views/form-modal';
import { ButtonCapture, ButtonWrapper } from '../../../../../forms';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { Rule } from '../rules-reducer';
import { ResultsRulesPopup } from './results-rules-condition-form-view';
import { ResultsRulesModal } from './results-rules-form';
import { getClaim } from '../../../../../claim';
import { ClaimType } from '../../../../../claim/claim-types';
import { Nullable } from '../../../../../shared-types';

interface Props extends StyledComponent {
    openModal: OpenModal;
    rules: Rule[];
    currentClaimType: Nullable<ClaimType>;
}

const rulesView = ({ classes, openModal, rules, currentClaimType }: Props) => {
    if (!rules || rules.length < 1) {
        return null;
    }

    const openEditView = () => {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => (
                        <ResultsRulesModal
                            index={0}
                            rules={rules}
                            currentClaimType={currentClaimType}
                        />
                    )}
                />
            ),
        });
    };
    const openRuleResults = (ruleSet: Rule) => {
        openModal({
            modalContent: (
                <FormModalView
                    closeButton={true}
                    formView={() => <ResultsRulesPopup rule={ruleSet} />}
                />
            ),
        });
    };

    return (
        <Grid container className={classes.topAndBottomMargin}>
            <Grid item xs={3} lg={4}>
                <Typography variant="subtitle2" className={classes.label}>
                    Rule Name
                </Typography>
            </Grid>
            {isDeathOrFuneral() && (
                <Grid item xs={2} lg={1}>
                    <Typography variant="subtitle2" className={classes.label}>
                        Rating
                    </Typography>
                </Grid>
            )}
            {isDeathOrFuneral() && (
                <Grid item xs={2} lg={1}>
                    <Typography variant="subtitle2" className={classes.label}>
                        Score
                    </Typography>
                </Grid>
            )}
            <Grid item xs={2} lg={1}>
                <Typography variant="subtitle2" className={classes.label}>
                    Override
                </Typography>
            </Grid>
            {isDeathOrFuneral() && (
                <Grid item xs={3} lg={5} container justify="space-between">
                    <ButtonCapture>
                        <Typography variant="subtitle2" className={classes.label}>
                            Comment
                        </Typography>
                    </ButtonCapture>
                    <Grid>
                        <ButtonWrapper>
                            <Tooltip title="override rules" style={{ marginTop: '-8px' }}>
                                <Button
                                    id="override-rules"
                                    color="secondary"
                                    onClick={openEditView}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            )}
            {!isDeathOrFuneral() && (
                <Grid item xs={3} lg={7} container justify="space-between">
                    <ButtonCapture>
                        <Typography variant="subtitle2" className={classes.label}>
                            Comment
                        </Typography>
                    </ButtonCapture>
                    <Grid>
                        <ButtonWrapper>
                            <Tooltip title="override rules" style={{ marginTop: '-8px' }}>
                                <Button
                                    id="override-rules"
                                    color="secondary"
                                    onClick={openEditView}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            )}
            {rules.map((ruleSet, index) => {
                return (
                    <React.Fragment key={`rule-set-${index}`}>
                        <Grid item xs={3} lg={4}>
                            <Typography
                                className={[classes.value, classes.hyperLink].join(' ')}
                                color="secondary"
                                onClick={() => openRuleResults(ruleSet)}
                            >
                                {ruleSet.description}
                            </Typography>
                        </Grid>
                        {isDeathOrFuneral() && (
                            <Grid item xs={2} lg={1}>
                                <Typography className={classes.value}>{ruleSet.rating}</Typography>
                            </Grid>
                        )}
                        {isDeathOrFuneral() && (
                            <Grid item xs={2} lg={1}>
                                <Typography className={classes.value}>
                                    {ruleSet.scoreValue}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={2} lg={1}>
                            {ruleSet.rating !== 'Reject' && (
                                <Checkbox
                                    checked={Boolean(ruleSet.override)}
                                    disabled
                                    style={{ margin: 0, padding: 0 }}
                                />
                            )}
                        </Grid>
                        <Grid item xs={3} lg={5}>
                            <Typography className={classes.value}>{ruleSet.comment}</Typography>
                        </Grid>
                    </React.Fragment>
                );
            })}
        </Grid>
    );

    function isDeathOrFuneral() {
        if (currentClaimType === ClaimType.Death || currentClaimType === ClaimType.Funeral) {
            return true;
        }
        return false;
    }
};

const mapStateToProps = (state: State) => ({
    currentClaimType: getClaim(state).claimType,
});

const mapDispatchToProps = { openModal: openModalAction };

export const ResultsRulesView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(rulesView));
