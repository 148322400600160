import { omit } from 'lodash';
import { isEmpty } from '../../forms/validations';
import validateContactDetails from '../actions/validate-contact-details';
import { ContactPersonDetails } from './contact-person-details';

export function validateContactPersonDetails(contactPerson: ContactPersonDetails) {
    if (
        !contactPerson ||
        contactPerson?.sameDetailsAsFa ||
        isEmpty(omit(contactPerson, 'sameDetailsAsFa'))
    ) {
        return {};
    }
    const errors = validateContactDetails(contactPerson);
    return errors;
}
