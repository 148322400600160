import React from 'react'; // eslint-disable-line

import { ContactPersonView } from '../common/contact-person';
import { ForensicInputView } from '../common/forensic-input';
import { IncomeManagementPlanView } from '../common/income-management-plan';
import { MedicalOfficerInputView } from '../common/medical-officer-input';
import { PolicySummaryView } from '../common/policy-summary';
import { ReinsuranceInputView } from '../common/reinsurance-input';
import { Synopsis } from '../common/synopsis/synopsis';
import { LifeAssuredView } from './life-assured-details/life-assured';
import { InformationRecordView } from '../common/information-record';
import { PolicyOwnerView } from '../common/policy-owner/views/policy-owner-v1';
import { AdditionalInformationRequestedView } from '../common/additional-information-requested';
import { InformationReceivedView } from '../common/information-received';
import { RulesResultsView } from '../common/rules/views/results-view';

export const RetrenchmentSynopsis = () => {
    return (
        <Synopsis>
            <ContactPersonView />
            <PolicyOwnerView />
            <LifeAssuredView />
            <PolicySummaryView />
            <InformationReceivedView />
            <AdditionalInformationRequestedView />
            <InformationRecordView />
            <MedicalOfficerInputView />
            <ForensicInputView />
            <ReinsuranceInputView />
            <IncomeManagementPlanView />
            <RulesResultsView />
        </Synopsis>
    );
};
