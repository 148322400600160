import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { connect } from 'react-redux';
import { OpenModal, openModalAction } from '../../../../form-modal/actions';
import { FormModalView } from '../../../../form-modal/views/form-modal';
import { ExpansionPanel } from '../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../redux/root-reducer';
import { RetrospectiveUnderwritingDecisionForm } from '../../../../retrospective-underwriting-decision';
import {
    getRetrospectiveUnderwritingDecision,
    RetrospectiveUnderwritingDecision,
} from '../../../../retrospective-underwriting-decision/reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { FinalUnderwritingDecisionForm } from './final-underwriting-decision-form';
import { PolicyBenefitFinalDecisionsView } from './policy-benefit-final-decisions';
import { LabeledValue } from '../../../../controls/labeled-value';
import { ButtonWrapper } from '../../../../forms';

type Props = StyledComponent & {
    retrospectiveUnderwritingDecision: RetrospectiveUnderwritingDecision;
    openModal: OpenModal;
    isReadOnly: boolean;
    isUnderWriting?: boolean;
};
const retrospectiveUnderwritingDecisionView: React.FunctionComponent<Props> = ({
    classes,
    retrospectiveUnderwritingDecision,
    openModal,
    isUnderWriting,
    isReadOnly,
}: Props) => {
    retrospectiveUnderwritingDecision = retrospectiveUnderwritingDecision || {
        decisionDetails: {},
        policyBenefitFinalDecisions: [],
    };

    const decisionDetails = retrospectiveUnderwritingDecision.decisionDetails;
    return (
        <ExpansionPanel name="Retrospective Underwriting Decision">
            <Grid container spacing={2} justify="space-between">
                <Grid item xs={3}>
                    <LabeledValue
                        label="Retrospective Underwriter"
                        value={decisionDetails.retrospectiveUnderwriter}
                    />
                </Grid>

                <ButtonWrapper>
                    <Grid item xs={2} lg={1} className={classes.rightAlign}>
                        <Tooltip title="Edit Retrospective Underwriting">
                            <Button
                                id="EditRetrospectiveUnderwriting"
                                color="secondary"
                                onClick={() => editUnderwriting()}
                            >
                                <Edit />
                            </Button>
                        </Tooltip>
                    </Grid>
                </ButtonWrapper>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Underwriting Decision"
                        value={decisionDetails.fullDisclosureAtApplicationStage}
                        isUnsafe
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Additional Information"
                        value={decisionDetails.additionalInformation}
                        isUnsafe
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledValue
                        label="Chief Medical Officer Comments"
                        value={decisionDetails.chiefMedicalOfficerComments}
                        isUnsafe
                    />
                </Grid>
                <Grid item xs={12} container justify="space-between">
                    <Grid item xs={10}>
                        <Typography variant="h6" className={classes.marginTop}>
                            Final Retrospective Underwriting Decisions Per Policy
                        </Typography>
                    </Grid>
                    <ButtonWrapper>
                        <Grid item xs={2} className={classes.rightAlign}>
                            <Tooltip title="Add/Edit Retrospective Underwriting Decisions">
                                <Button
                                    id="EditRetrospectiveDecisions"
                                    color="secondary"
                                    onClick={() => editDecisions()}
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </ButtonWrapper>
                </Grid>
                {retrospectiveUnderwritingDecision.policyBenefitFinalDecisions.map(
                    (policyBenefitFinalDecisions, benefitIndex) =>
                        policyBenefitFinalDecisions.finalDecisions.length > 0 && (
                            <Grid
                                item
                                xs={12}
                                key={`${policyBenefitFinalDecisions.policyNumber}${policyBenefitFinalDecisions.benefitName}${benefitIndex}`}
                            >
                                <PolicyBenefitFinalDecisionsView
                                    benefitIndex={benefitIndex}
                                    policyBenefitFinalDecisions={policyBenefitFinalDecisions}
                                />
                            </Grid>
                        )
                )}
            </Grid>
        </ExpansionPanel>
    );

    function editUnderwriting() {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => {
                        return <RetrospectiveUnderwritingDecisionForm hideFinalDecision />;
                    }}
                />
            ),
        });
    }

    function editDecisions() {
        openModal({
            modalContent: (
                <FormModalView
                    formView={() => {
                        return <FinalUnderwritingDecisionForm />;
                    }}
                />
            ),
        });
    }
};

const mapStateToProps = (state: State) => ({
    retrospectiveUnderwritingDecision: getRetrospectiveUnderwritingDecision(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const RetrospectiveUnderwritingDecisionView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(retrospectiveUnderwritingDecisionView));
