import React from 'react'; // eslint-disable-line
import { ButtonWrapper } from '../../../../forms';
import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { LabeledValue } from '../../../../controls/labeled-value';
import { FormHeading } from '../../../../forms';
import downarrow from '../../../../master-layout/views/downarrow.svg';
import uparrow from '../../../../master-layout/views/uparrow.svg';
import { State } from '../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../styles/styles';
import { GenerateSynopsis } from '../../../synopsis/common/generate-synopsis';
import {
    getRaDeathClaimDetails,
    RAClaimDetails,
} from '../../../synopsis/ra-death/claim-details/reducer';
import { RaiseLiability, raiseLiabilityAction } from '../../actions/raise-liability';
import { LiabilityStatusView } from '../../common/views/liability-status-view';
import {
    AssessmentPolicy,
    getRaDeathAssessmentDetails,
} from '../ra-death-assessment-details-reducer';
import { getDisplayDate, formatCurrency, getTimeLapsed } from '../../../../shared-functions';
import { ClaimLiability } from '../../assessment-details-reducer';
import { RaDeathAssessmentDecisionView } from './ra-death-assessment-decision-view';
import { ClaimType } from '../../../../claim/claim-types';

interface OwnProps {}

interface DispatchProps {
    raiseLiability: RaiseLiability;
}

interface StateProps {
    readonly assessmentPolicies: AssessmentPolicy[];
    readonly claimDetails: RAClaimDetails;
    isReadOnly: boolean;
}

type Props = StateProps & DispatchProps & OwnProps & StyledComponent;
const raDeathAssessmentDetailsView: React.FunctionComponent<Props> = ({
    assessmentPolicies,
    claimDetails,
    classes,
    raiseLiability,
    isReadOnly,
}: Props) => {
    return (
        <React.Fragment>
            <Paper className={classes.paper}>
                <FormHeading>Assessment Decisions</FormHeading>
                {assessmentPolicies.map((assessmentPolicy, index) => {
                    return (
                        <Grid container justify="flex-start" key={`assessment_${index}`}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                    className={`${classes.marginTop} ${classes.marginBottom}`}
                                >
                                    Policy number - {assessmentPolicy.policyNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.marginBottom}>
                                    Product name - {assessmentPolicy.productName}
                                </Typography>
                            </Grid>
                            <Grid container justify="space-between">
                                <Grid item container>
                                    <Grid item xs={10}>
                                        <LiabilityStatusView
                                            claimLiability={assessmentPolicy.claimLiability}
                                            claimType={ClaimType.RADeath}
                                        />
                                    </Grid>

                                    {canRaiseLiability() && (
                                        <ButtonWrapper>
                                            <Grid
                                                container
                                                item
                                                xs={2}
                                                justify="flex-end"
                                                style={{ paddingRight: 5, marginTop: '-15px' }}
                                            >
                                                <Button
                                                    id="ra-death-raise-liability"
                                                    color="secondary"
                                                    style={{ padding: 10, textTransform: 'none' }}
                                                    onClick={() => liability()}
                                                >
                                                    <Cached />
                                                    Raise liability
                                                </Button>
                                            </Grid>
                                        </ButtonWrapper>
                                    )}
                                </Grid>
                            </Grid>
                            <AssessmentPolicyView
                                assessmentPolicy={assessmentPolicy}
                                claimLiability={assessmentPolicy.claimLiability}
                                effectiveDate={claimDetails.claimEventDetails.dateOfEvent}
                            />
                        </Grid>
                    );

                    function liability() {
                        raiseLiability({
                            policyNumber: assessmentPolicy.policyNumber,
                            benefitCode: assessmentPolicy.benefitCode,
                            causeOfDeath: claimDetails.claimEventDetails.causeOfDeath,
                            dateOfDeath: claimDetails.claimEventDetails.dateOfEvent,
                        });
                    }

                    function canRaiseLiability() {
                        return (
                            assessmentPolicy.policyNumber &&
                            assessmentPolicy.claimLiability.status !== 'Successful' &&
                            claimDetails.claimEventDetails.dateOfEvent
                        );
                    }
                })}
                <RaDeathAssessmentDecisionView />
            </Paper>
            <GenerateSynopsis />
        </React.Fragment>
    );
};

interface AssessmentPolicyProps {
    assessmentPolicy: AssessmentPolicy;
    claimLiability: ClaimLiability;
    effectiveDate?: Date;
}
const AssessmentPolicyView: React.FunctionComponent<AssessmentPolicyProps> = ({
    assessmentPolicy,
    claimLiability,
    effectiveDate,
}: AssessmentPolicyProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toggleState = (evt: any) => {
        setIsExpanded(value => !value);
        evt.preventDefault();
        return false;
    };
    const backgroundColor = isExpanded
        ? {
              backgroundColor: '#F0F1F4',
              paddingRight: '8px',
          }
        : { paddingRight: '8px' };
    return (
        <Grid container spacing={0} justify="flex-start" style={backgroundColor}>
            <Grid item xs={12} container justify="flex-end">
                <Tooltip title={isExpanded ? 'Hide policy details' : 'View policy details'}>
                    <a href="/#" onClick={toggleState}>
                        {isExpanded ? (
                            <img alt="collapse" src={uparrow} />
                        ) : (
                            <img alt="expand" src={downarrow} />
                        )}
                    </a>
                </Tooltip>
            </Grid>
            {isExpanded && (
                <Grid container justify="flex-start" style={{ marginLeft: '8px' }}>
                    <Grid item xs={3}>
                        <LabeledValue label="Policy number" value={assessmentPolicy.policyNumber} />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue label="Product name" value={assessmentPolicy.productName} />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Inception date"
                            value={getDisplayDate(assessmentPolicy.commencementDate)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue label="Claim type" value="RA Death" />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Effective date"
                            value={getDisplayDate(effectiveDate)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <LabeledValue
                            label="Time lapse"
                            value={`${getTimeLapsed(new Date(), effectiveDate)} years`}
                        />
                    </Grid>
                    <Grid item xs={12} container justify="flex-start">
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Quote amount"
                                value={formatCurrency(claimLiability.quoteAmount)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Claim amount"
                                value={formatCurrency(claimLiability.claimAmount)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Overpayment amount"
                                value={formatCurrency(claimLiability.overpaymentAmount)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LabeledValue
                                label="Money in suspense"
                                value={formatCurrency(claimLiability.moneyInSuspense)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => ({
    assessmentPolicies: getRaDeathAssessmentDetails(state).assessmentPolicies,
    claimDetails: getRaDeathClaimDetails(state),
    isReadOnly: state.claimSession.readOnly,
});

const mapDispatchToProps = {
    raiseLiability: raiseLiabilityAction,
};

export const RaDeathAssessmentDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(raDeathAssessmentDetailsView));
