import React from 'react'; // eslint-disable-line

import { Button, Grid, Tooltip, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { connect, MapDispatchToProps, MapStateToProps } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuid } from 'uuid';
import { AuthState } from '../../../../../auth/auth-reducer';
import { AddFormModal } from '../../../../../form-modal';
import { OpenAddModal, openAddModalAction } from '../../../../../form-modal/actions';
import { ExpansionPanel } from '../../../../../forms/form-components/expansion-panel/expansion-panel';
import { State } from '../../../../../redux/root-reducer';
import styles, { StyledComponent } from '../../../../../styles/styles';
import { InformationRecordDetailsView } from './information-record-details';
import { InformationRecord, getInformationRecord } from '../reducer';
import {
    AddAndSubmitInformationRecord,
    addAndSubmitInformationRecordThunk,
} from '../actions/submit-information-record';
import InformationReceivedRecordDetailView from './information-received-record-detail';
import { getClaim } from '../../../../../claim';
import { ButtonWrapper } from '../../../../../forms';

interface OwnProps {}

interface StateProps {
    currentUser: AuthState;
    records: InformationRecord[];
    versionNumber: string;
    isReadOnly: boolean;
}

interface DispatchProps {
    addRecord: AddAndSubmitInformationRecord;
    openAddModal: OpenAddModal;
}

type Props = DispatchProps & OwnProps & StateProps & StyledComponent;

const informationRecordView: React.FunctionComponent<Props> = ({
    addRecord,
    classes,
    currentUser,
    records,
    openAddModal,
    versionNumber,
    isReadOnly,
}: Props) => {
    if (versionNumber < '1.2.0.0') {
        return null;
    }
    return (
        <ExpansionPanel name={'Claims Information Record'}>
            <ButtonWrapper>
                <Grid container spacing={5} justify="flex-start">
                    <Grid item xs={12} lg={12} className={classes.rightAlign}>
                        {addInformationRecordButton(addRecord, currentUser, openAddModal)}
                    </Grid>
                </Grid>
            </ButtonWrapper>
            {records.map((record, index) => {
                return (
                    <InformationRecordDetailsView
                        classes={classes}
                        index={index}
                        key={`Summary-${index}`}
                        informationRecord={record}
                    />
                );
            })}
        </ExpansionPanel>
    );
};
const InformationRecordAddFormModal = AddFormModal<InformationRecord>();
const addInformationRecordButton = (
    addAndSubmitInformationRecord: AddAndSubmitInformationRecord,
    currentUser: AuthState,
    openAddModal: OpenAddModal
) => {
    let assessedBy = 'Unknown';
    if (currentUser) {
        assessedBy = `${currentUser.name} (${currentUser.userId})`;
    }

    const blankInformationRecord: InformationRecord = {
        id: uuid(),
        assessedBy: assessedBy,
        assessor: assessedBy,
        dateOfSummary: new Date(),
    };

    const openAddView = () => {
        openAddModal({
            modalContent: (
                <InformationRecordAddFormModal
                    blankRecord={blankInformationRecord}
                    recordDetailView={
                        <InformationReceivedRecordDetailView
                            modalType="Add"
                            heading={'Add Information Records'}
                            nonBusinessRelatedExpenses={
                                blankInformationRecord.selfEmployedIncome &&
                                blankInformationRecord.selfEmployedIncome.nonBusinessRelatedExpenses
                            }
                        />
                    }
                    addRecord={informationRecord =>
                        addAndSubmitInformationRecord(informationRecord)
                    }
                />
            ),
            modalData: {},
        });
    };

    return (
        <ButtonWrapper>
            <Tooltip title="Add Information Records">
                <Button id="AddInformationReceived" color="secondary" onClick={openAddView}>
                    <AddCircle />
                </Button>
            </Tooltip>
        </ButtonWrapper>
    );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (state: State) => {
    const claim = getClaim(state);
    const versionNumber = claim.version ? claim.version.createdInVersion || '1.1.0.0' : '1.1.0.0';
    return {
        records: getInformationRecord(state).records,
        currentUser: state.auth as AuthState,
        versionNumber: versionNumber,
        isReadOnly: state.claimSession.readOnly,
    };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: ThunkDispatch<State, any, AnyAction>
) => ({
    addRecord: (informationRecord: InformationRecord) =>
        dispatch(addAndSubmitInformationRecordThunk(informationRecord)),
    openAddModal: options => dispatch(openAddModalAction(options)),
});

export const InformationRecordView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(informationRecordView));
