import React from 'react'; // eslint-disable-line
import { connect } from 'react-redux';
import { withStyles, Grid, Typography, Paper } from '@material-ui/core';
import { ExpansionPanel } from '../../../../../../forms/form-components/expansion-panel/expansion-panel';
import styles, { StyledComponent } from '../../../../../../styles/styles';
import { LabeledValue } from '../../../../../../controls/labeled-value';
import { State } from '../../../../../../redux/root-reducer';
import { getFormDetails } from '../../form-details-reducer';
import { FormDetails } from '../../form-details-reducer';
import { OpenModal, openModalAction } from '../../../../../../form-modal/actions';

interface BaseProps {
    formDetails?: FormDetails;
    disableEditButton?: boolean;
}

interface Props extends BaseProps, StyledComponent {
    readonly openModal: OpenModal;
}

const DeclarationViewComponent: React.FunctionComponent<Props> = ({
    classes,
    openModal,
    formDetails,
    disableEditButton,
}: Props) => {
    const declarationInformation = formDetails?.declarationInformation;

    return (
        <ExpansionPanel name="Declaration" style={{ marginLeft: -24, marginRight: -24 }}>
            <React.Fragment>
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.marginTop2}
                >
                    <Typography style={{ padding: 10 }} className={classes.heading} variant="body1">
                        Declaration Information
                    </Typography>
                </Grid>

                <Paper className={classes.paper} style={{ padding: '16px 24px', margin: '0 16px' }}>
                    <Grid container item xs={12} justify="flex-end" alignItems="center"></Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LabeledValue
                                label=""
                                value={
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: declarationInformation || '',
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Paper>
                <br />
            </React.Fragment>
        </ExpansionPanel>
    );
};

const mapStateToProps = (state: State, ownProps: BaseProps) => ({
    formDetails: ownProps.formDetails || getFormDetails(state),
    disableEditButton: ownProps.disableEditButton || false,
    openModal: openModalAction,
});

const mapDispatchToProps = {
    openModal: openModalAction,
};

export const DeclarationInformationView = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DeclarationViewComponent));
