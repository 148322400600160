import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react'; // eslint-disable-line
import { FullWidthTextField } from '../../../../../forms';
import { FirstNameMask, InitialsMask, TelephoneNumberMask } from '../../../../../forms/input-masks';
import { AddressDetailsSubForm } from '../../../../../forms/sub-forms/address-details';
import { requiredValidator } from '../../../../../forms/validations';

type Props = {
    heading: React.ReactNode;
};
export const MedicalDoctorForm: React.FunctionComponent<Props> = ({ heading }: Props) => {
    return (
        <React.Fragment>
            <Typography variant="h5">{`${heading}`} </Typography>
            <Divider></Divider>
            <Grid container spacing={5} justify="flex-start">
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="initials"
                        label="Initials"
                        mask={InitialsMask}
                        inputProps={{
                            style: { textTransform: 'uppercase' },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="name"
                        label="First Name"
                        mask={FirstNameMask}
                        inputProps={{
                            style: { textTransform: 'capitalize' },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="surname"
                        label="Last Name"
                        validate={requiredValidator}
                        mask={FirstNameMask}
                        inputProps={{
                            style: { textTransform: 'capitalize' },
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField
                        name="telephoneNumber"
                        label="Contact Number"
                        mask={TelephoneNumberMask}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FullWidthTextField name="registrationNumber" validate={requiredValidator} />
                </Grid>
                <AddressDetailsSubForm addressType="residential" />
            </Grid>
        </React.Fragment>
    );
};
